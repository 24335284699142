import './event-list.css';

import {IconName} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import EventCard  from './event-card';
import useEvents, {EventRow, eventState} from './use-event-service';
import useQueryParameter from './use-query-parameter';
import useToggleResource from './use-toggle-resource';
import { userState } from './user';
//
// export interface EventGroupInfo {
//   parent: Identifier[];
//   group: Identifier;
//   open: boolean;
//   type?: string;
//   component?: string;
//   icon?: string;
//   user?: string;
//   minTimeStamp?: DateTime;
//   maxTimeStamp?: DateTime;
//   children: Array<EventGroupInfo | EventDisplay>;
// }

interface EventGroupProps {
  group: EventRow;
  level: number;
  groupClicked: (event: EventRow, level?: number) => void;
}

// function sortByTimestamp(
//   a: EventGroupInfo | EventDisplay,
//   b: EventGroupInfo | EventDisplay,
// ): number {
//   const aIsGroup = isGroup(a);
//   const bIsGroup = isGroup(b);
//   if (aIsGroup && bIsGroup) {
//     const groupA = a as EventGroupInfo;
//     const groupB = b as EventGroupInfo;
//     const diff =
//       groupA.maxTimeStamp!.toMillis() - groupB.maxTimeStamp!.toMillis();
//     if (diff > 0) return -1;
//     if (diff < 0) return 1;
//     return 0;
//   } if (aIsGroup) {
//     const groupA = a as EventGroupInfo;
//     const eventB = b as EventDisplay;
//     const diff = eventB.timestamp!.toMillis() - groupA.maxTimeStamp!.toMillis();
//     if (diff < 0) return -1;
//     if (diff > 0) return 1;
//     return 0;
//   } if (bIsGroup) {
//     const eventA = a as EventDisplay;
//     const groupB = b as EventGroupInfo;
//     const diff = groupB.maxTimeStamp!.toMillis() - eventA.timestamp!.toMillis();
//     if (diff < 0) return -1;
//     if (diff > 0) return 1;
//     return 0;
//   }
//   const eventA = a as EventDisplay;
//   const eventB = b as EventDisplay;
//   if (eventA.timestamp.toMillis() > eventB.timestamp.toMillis()) return -1;
//   if (eventA.timestamp.toMillis() < eventB.timestamp.toMillis()) return 1;
//   return 0;
// }
//
// function sortByRangeTimestamp<T extends EventGroupInfo | EventDisplay>(
//   event: T,
// ): T {
//   const testKey: keyof EventGroupInfo = 'group';
//   if (Object.keys(event).includes(testKey)) {
//     const group = event as EventGroupInfo;
//     const updatedChildren = group.children.map((child) => {
//       if (Object.keys(child).includes(testKey)) {
//         return sortByRangeTimestamp(child as EventGroupInfo);
//       }
//       return child;
//     });
//     return {
//       ...group,
//       children: updatedChildren.sort(sortByTimestamp),
//     } as T;
//   }
//   return event;
// }
//
// function sortByRangeTimestamps<T extends (EventGroupInfo | EventDisplay)[]>(
//   events: T,
// ): T {
//   return events.sort(sortByTimestamp).map(sortByRangeTimestamp) as T;
// }
//
// function groupWithTimeRange<T extends EventGroupInfo | EventDisplay>(
//   event: T,
// ): T {
//   const testKey: keyof EventGroupInfo = 'group';
//   if (Object.keys(event).includes(testKey)) {
//     const group = event as EventGroupInfo;
//     if (group.minTimeStamp && group.maxTimeStamp) return event;
//     let max = DateTime.fromMillis(0);
//     let min = DateTime.fromMillis(DateTime.now().toMillis() * 10);
//     const updatedChidren = group.children.map((child) => {
//       if (Object.keys(child).includes(testKey)) {
//         const eventGroup = child as EventGroupInfo;
//         const updated = groupWithTimeRange(eventGroup);
//         min = DateTime.min(updated.minTimeStamp!, min);
//         max = DateTime.max(updated.maxTimeStamp!, max);
//         return updated;
//       }
//       const eventInfo = child as EventDisplay;
//       min = DateTime.min(eventInfo.timestamp, min);
//       max = DateTime.max(eventInfo.timestamp, max);
//       return eventInfo;
//     });
//     return {
//       ...group,
//       children: updatedChidren,
//       minTimeStamp: min,
//       maxTimeStamp: max,
//     } as T;
//   }
//   return event;
// }
// function groupsWithTimeRange(
//   groups: (EventGroupInfo | EventDisplay)[],
// ): (EventGroupInfo | EventDisplay)[] {
//   return groups.map(groupWithTimeRange);
// }
//
// function getNextGroup(
//   parent: Identifier[],
//   elements: EventDisplay[],
// ): (EventGroupInfo | EventDisplay)[] {
//   if (elements.length === 0) return [];
//   const elementsUnder = elements.filter(
//     (it) =>
//       it.idChain.length > parent.length &&
//       JSON.stringify(it.idChain.slice(0, parent.length)) ===
//         JSON.stringify(parent),
//   );
//   const singleEvents = elementsUnder.filter(
//     (it) => it.idChain.length === parent.length + 1,
//   );
//   const groupElements = elementsUnder.filter(
//     (it) => it.idChain.length > parent.length + 1,
//   );
//   const ids = groupElements.map((it) => it.idChain[parent.length]);
//   const nextIdentifiers = ids.filter(
//     (it, index) =>
//       ids.findIndex((id) => id.identifier === it.identifier) === index,
//   );
//   return [
//     ...singleEvents,
//     ...nextIdentifiers.map((identifier) => {
//       const children = getNextGroup([...parent, identifier], groupElements);
//       const childComponent = children.find(it => !!it.component)?.component;
//       const sharedComponent = childComponent ? children.some(it => !!it.component && it.component !== childComponent) ? undefined : childComponent : undefined;
//       const childUser = children.find(it => !!it.user)?.user;
//       const sharedUser = childUser ? children.some(it => !!it.user && it.user !== childUser) ? undefined : childUser : undefined;
//       const childIcon = children.find(it => !!it.icon)?.icon;
//       const sharedIcon = childIcon ? children.some(it => !!it.icon && it.icon !== childIcon) ? undefined : childIcon : undefined;
//       return {
//         group: identifier,
//         parent,
//         open: false,
//         children,
//         component: sharedComponent,
//         user: sharedUser,
//         icon: sharedIcon,
//         type: children.some((child) => child.type === 'ERROR')
//           ? 'ERROR'
//           : children.some((child) => child.type === 'WARNING')
//           ? 'WARNING'
//           : 'INFO',
//       };
//     }),
//   ];
// }

const EventGroup: FunctionComponent<EventGroupProps> = (props: EventGroupProps) => {
  function childRender(child: EventRow): ReactElement {
    if (child.type === 'group') {
      return (
        <EventGroup
          key={(child.event as any).eventHash}
          group={child}
          level={props.level + 1}
          groupClicked={props.groupClicked}
        />
      );
    }
    return (
      <EventCard
        key={(child.event as any).eventHash}
        info={child}
        onHeaderClick={() => {props.groupClicked(child)}}
      />
    );
  }

  function titled(title: string, content: ReactElement, className?: string): ReactElement {
    return (
      <div className={`group-titled ${className ?? ''}`}>
        <div className="titled-title">{title}</div>
        <div className="titled-content">{content}</div>
      </div>
    );
  }
  
  const {group} = props;
  const identifier = group.event.idChain[group.event.idChain.length - 1]

  return <div>
    <div className="group-collection">
      <div className="group-padding" />
      <div className="group-display">
        <div
          role="group"
          className={`group-parent-header ${group.event.type?.toLowerCase()}`}
          onClick={(e) => {
            e.stopPropagation();
            props.groupClicked(group, props.level);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            props.groupClicked(group, props.level);
          }}
        >
          <div
            className={
              `group-expander ${group.open ? 'open' : 'closed'}`
            }
          />
          <div className="vertical-bar" />
          <div className="group-detail">
            {titled(identifier.name, <div>{identifier.identifier}</div>)}
            {group.event.component ? titled('Component', <div>{group.event.icon ? <FontAwesomeIcon
              className="event-icon"
              icon={{
                iconName: group.event.icon as IconName,
                prefix: 'fas',
              }}
            /> : <></>}{group.event.component}</div>, 'event-component') : <></>}
            {group.event.user ? titled('User', <div> <FontAwesomeIcon
              className="event-icon"
              icon={{
                iconName: 'user',
                prefix: 'fas',
              }}
            />{group.event.user}</div>, 'event-user') : <></>}
          </div>
          {titled('Events', <div>{(group.event as any).events}</div>, 'event-count')}
          {/* {groupSpark()} */}
          <div className="time-group">
            <div className="time-start">
              {DateTime.fromISO((group.event as any).startTime).toFormat('yyyy-MM-dd HH:mm:ss.SSS')}
            </div>
            <div className="time-end">
              {DateTime.fromISO(group.event.timestamp).toFormat('yyyy-MM-dd HH:mm:ss.SSS')}
            </div>
          </div>
        </div>
        {group.open ? group.children.map(childRender) : <></>}
      </div>
    </div>
  </div>;
};

function selectedGroup(
  selected: string[],
  event: EventRow,
): EventRow {
  return {
    ...event,
    open: selected.includes((event.event as any).eventHash),
    children: event.children.map((child) => selectedGroup(selected, child))
  }
}

function selectedGroups(
  selected: string[],
  groups: EventRow[],
): EventRow[] {
  return groups.map((group) => selectedGroup(selected, group));
}

const EventList: FunctionComponent = () => {
  const selected = useQueryParameter('selected');
  const select = useToggleResource('selected');
  const events = useRecoilValue(eventState);
  const eventService = useEvents();
  const user = useRecoilValue(userState);
  // const categorizedGroups = getNextGroup(
  //   [],
  //   events.map((event) => ({
  //     ...event,
  //     open: false,
  //     timestamp: DateTime.fromISO(event.timestamp),
  //   })),
  // );
  // const timed = groupsWithTimeRange(categorizedGroups);
  // const groups = sortByRangeTimestamps(timed);

  useEffect(() => {
    eventService(user?.jwtToken!);
  }, [user?.jwtToken]);
  return (
    <div className="event-namespace">
      <div className="namespace-title">klouds.io</div>
      <div className="event-list">
        {selectedGroups(selected, events).map((event) => {
          if (event.type === 'group') {
            return (
              <EventGroup
                key={(event.event as any).eventHash}
                level={1}
                group={event}
                groupClicked={(clicked, level) => {
                  if (clicked.children.length === 0 && !clicked.open && clicked.type === 'group') {
                    eventService(user?.jwtToken!, (clicked.event as any).eventHash, level);
                  }
                  select((clicked.event as any).eventHash);
                }}
              />
            );
          }
          return (
            <EventCard
              info={event}
              onHeaderClick={() => select((event.event as any).eventHash)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventList;
