import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { useSetRecoilState } from 'recoil';

import { userPool } from './environment';
import { extractUserFrom, userState } from './user';

function useAuthenticate(): (
  cognitoUsername: string,
  password: string,
) => Promise<void> {
  const setUser = useSetRecoilState(userState);
  return async (cognitoUsername: string, password: string) => {
      const session = await new Promise<CognitoUserSession>(
        (resolve, reject) => {
          new CognitoUser({
            Username: cognitoUsername,
            Pool: userPool,
          }).authenticateUser(
            new AuthenticationDetails({
              Username: cognitoUsername,
              Password: password,
            }),
            {
              onSuccess: (userSession) => resolve(userSession),
              onFailure: (error) => reject(error),
            },
          );
        },
      );
      setUser(extractUserFrom(session));
  };
}

export default useAuthenticate;
