import { useEffect } from 'react';

import useApplySession from './use-apply-session';

const useSession = () => {
  const applySession = useApplySession();

  useEffect(() => {
    const interval = setInterval(() => {
      applySession();
    }, 300000);

    return () => clearInterval(interval);
  }, []);
};

export default useSession;
