import * as React from 'react';
import { memo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userState } from './user';

const PrivateRoute: React.FunctionComponent<RouteProps> = (
  props,
): JSX.Element => {
  const user = useRecoilValue(userState);

  return user ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={
        window.location.search
          ? `/login?redirect=${encodeURIComponent(
              window.location.pathname + window.location.search,
            )}`
          : '/login'
      }
    />
  );
};

export default memo(
  PrivateRoute,
  (a, b) => JSON.stringify(a) === JSON.stringify(b),
);
