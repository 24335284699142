import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { atom } from 'recoil';

interface User {
  username: string;
  email: string;
  jwtToken: string;
}

export const userState = atom<User | undefined>({
  key: 'userState',
  default: undefined,
});

export const extractUserFrom = (session: CognitoUserSession) => {
  const { 'cognito:username': username, email } = session.getIdToken().payload;
  return {
    username,
    email,
    jwtToken: session.getIdToken().getJwtToken(),
  };
};

export const getSession = (user: CognitoUser) =>
  new Promise<CognitoUserSession>((resolve, reject) => {
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        return reject(error);
      }
      return resolve(session);
    });
  });

export const getRefreshedSession = (
  user: CognitoUser,
  refreshToken: CognitoRefreshToken,
) =>
  new Promise<CognitoUserSession>((resolve, reject) => {
    user.refreshSession(
      refreshToken,
      (error: Error | null, session: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }
        return resolve(session);
      },
    );
  });
