import './event-card.css';

import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventInfo } from '@hexlabsio/klouds-events-sdk/dist/generated/event-service-api/sdk';
import { DateTime } from 'luxon';
import React, { FunctionComponent, ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';

import {EventRow} from "./use-event-service";

library.add(fas);

export interface Identifier {
  name: string;
  key: string;
  identifier: string;
}
export type EventDisplay = Omit<EventInfo, 'timestamp'> & {
  open: boolean;
  timestamp: DateTime;
};

interface EventCardProps {
  info: EventRow;
  onHeaderClick: (identifier: Identifier) => void;
}

const EventCard: FunctionComponent<EventCardProps> = (props: EventCardProps) => {
  function titled(title: string, content: ReactElement): ReactElement {
    return (
      <div className="group-titled">
        <div className="titled-title">{title}</div>
        <div className="titled-content">{content}</div>
      </div>
    );
  }
  const {info} = props;
  const {event} = info;
  const identifier = event.idChain[event.idChain.length - 1];
  const {timestamp} = event;
  return (
    <div
      className={
        `event-card ${
          event.type.toLowerCase()
        } ${
          info.open ? 'open' : 'closed'}`
      }
    >
      <div
        className="event-card-header"
        role='group'
        onClick={(e) => {
          e.stopPropagation();
          props.onHeaderClick(identifier);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          props.onHeaderClick(identifier);
        }}
      >
        <div className="event-card-info">
          <div className="event-card-title">
            {titled('Title', <div>{event.title}</div>)}
          </div>
          <div className="event-card-component">
            {titled(
              'Component',
              <div className="event-card-icon-title">
                {event.icon ? (
                  <FontAwesomeIcon
                    className="event-icon"
                    icon={{
                      iconName: event.icon as IconName,
                      prefix: 'fas',
                    }}
                  />
                ) : (
                  <></>
                )}
                {event.component}
              </div>,
            )}
          </div>
          <div className="event-card-summary">
            {titled('Summary', <div>{event.summary}</div>)}
          </div>
          <div className="event-card-user">
            {titled(
              'User',
              <div className="event-card-icon-title">
                <FontAwesomeIcon
                    className="event-icon"
                    icon={{ iconName: 'user', prefix: 'fas' }}
                  />
                {event.user ?? '--'}
              </div>,
            )}
          </div>
        </div>
        <div className="event-card-timestamp">
          {titled('Date', <div>{DateTime.fromISO(timestamp).toFormat('yyyy-MM-dd')}</div>)}
          {titled('TimeZone', <div>{DateTime.fromISO(timestamp).toFormat('ZZZZZ (Z)')}</div>)}
          {titled('Time', <div>{DateTime.fromISO(timestamp).toFormat('HH:mm:ss.SSS')}</div>)}
        </div>
      </div>
      {info.open && event.description ? (
        <div className="event-body">
          <ReactMarkdown>{event.description}</ReactMarkdown>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EventCard;
