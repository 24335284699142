import axios from 'axios';
import qs from 'qs';

// eslint-disable-next-line import/prefer-default-export
export const authenticatedAxios = (jwtToken: string) => ({
  call: async (
    method: any,
    resource: any,
    path: string,
    body: any,
    pathParameters: any,
    queryParameters: any,
    multiQueryParameters: any,
    headers: any
  ) => {
    const updatedHeaders = { ...headers, Authorization: `Bearer ${jwtToken}` };
    const result = await axios(`https://api.dev.klouds.io/events${  path}`, {
      method: method as any,
      data: body,
      paramsSerializer: (params) => qs.stringify(params),
      params: { ...queryParameters, ...multiQueryParameters },
      headers: updatedHeaders,
      transformResponse: [],
    });
    return {
      statusCode: result.status,
      body: result.data,
      headers: result.headers as any,
    };
  },
});
