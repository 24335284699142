import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { atomFamily, useRecoilState } from 'recoil';

export const queryState = atomFamily<string[], string>({
  key: 'queryState',
  default: [],
});
const useQueryParameter = (name: string) => {
  const { search } = useLocation();
  const [query, setQuery] = useRecoilState(queryState(name));
  useEffect(() => {
    const current = new URLSearchParams(search).getAll(name);
    if (JSON.stringify(current) !== JSON.stringify(query)) {
      setQuery(current);
    }
  }, [search]);
  return query;
};
export default useQueryParameter;
