import { useHistory } from 'react-router-dom';

const useToggleResource = (queryKey: string) => {
  const history = useHistory();
  return (resourceArn: string) => {
    const query = new URLSearchParams(window.location.search);
    const resources = query.getAll(queryKey);
    if (resources.includes(resourceArn)) {
      query.delete(queryKey);
      resources
        .filter((x) => x !== resourceArn)
        .forEach((queryResource) => {
          query.append(queryKey, queryResource);
        });
    } else {
      query.append(queryKey, resourceArn);
    }
    history.replace({ search: query.toString() });
  };
};

export default useToggleResource;
