import { defineEnvironmentFromProcess } from '@hexlabs/env-vars-ts';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

const { environment } = defineEnvironmentFromProcess(
  [
    'REACT_APP_ENV',
    'REACT_APP_USER_POOL_ID',
    'REACT_APP_CLIENT_ID'
  ] as const,
  [],
);
export const userPool = new CognitoUserPool({
  UserPoolId: environment.REACT_APP_USER_POOL_ID,
  ClientId: environment.REACT_APP_CLIENT_ID,
});

export default environment;
