import './with-navigation.css'

import * as React from 'react';
import {useRecoilValue} from "recoil";

import useSession from './use-session';
import {userState} from "./user";

const withNavigation =
  <P extends object>(ComposedComponent: React.ComponentType<P>) =>
  (props: P) => {
    useSession();
    const user = useRecoilValue(userState);
    return (
      <div className="grouped-nav">
        <div className="top-nav"><div style={{flex: 1}}>Event Scope</div><div>{user?.email}</div></div>
        <ComposedComponent {...(props as P)} />
      </div>
    );
  };

export default withNavigation;
