import {
  EventInfo,
  EventServiceApiSdk,
} from '@hexlabsio/klouds-events-sdk/dist/generated/event-service-api/sdk';
import { DateTime, Duration } from 'luxon';
// @ts-ignore
import qs from 'query-string';
import { atom, useSetRecoilState } from 'recoil';

import { authenticatedAxios } from './caller';
import useQueryParameter from './use-query-parameter';

export const useEventSdk = () => (jwtToken: string) =>
  new EventServiceApiSdk(authenticatedAxios(jwtToken), { environment: 'dev' });

export interface EventRow {
  event: EventInfo;
  type: 'group' | 'event';
  open: boolean;
  children: EventRow[];
}

export const eventState = atom<EventRow[]>({
  key: 'eventState',
  default: [],
});

const useEvents = () => {
  const start = useQueryParameter('start')[0];
  const end = useQueryParameter('end')[0];
  const startTime = start || DateTime.now()
        .minus(Duration.fromMillis(1000 * 60 * 60 * 2))
        .toISO();
  const endTime = end || DateTime.now().toISO();
  const eventSdk = useEventSdk();
  const setEvents = useSetRecoilState(eventState);
  
  async function getNextEvents(jwtToken: string, parent?: string, level?: number, next?: string): Promise<EventInfo[] | undefined> {
    const response = await eventSdk(jwtToken).getEvents(
      { start: startTime, end: endTime, next, parent, level: level?.toString()} as any,
      {},
    );
    if(response.statusCode === 200) {
      const eventCollection = response.result;
      if(eventCollection.next) {
        const nextParam = qs.parse(qs.extract(eventCollection.next)).next as string;
        if(nextParam) {
          const nextEvents = await getNextEvents(jwtToken, nextParam);
          if(nextEvents)
            return [...eventCollection.member, ...nextEvents];
          return undefined;
        }
      }
      return eventCollection.member;
    }
    return undefined;
    
    
  }
  
  function updatedEvent(children: EventRow[], event: EventRow, parent: string, matchLevel: number, level = 1): EventRow {
    if(level === matchLevel && (event.event as any).eventHash === parent) {
      return {...event, children };
    }
    if(level > matchLevel) {
      return event;
    }
    return {...event, children: event.children.map(child => updatedEvent(children, child, parent, matchLevel, level + 1))};
  }

  return async (jwtToken: string, parent?: string, level?: number) => {
    const events = await getNextEvents(jwtToken, parent, level ? level! + 1 : undefined);
    if (events) {
      const rows = events.map(event => ({open: false, event, children: [], type: (event as any).entryType}));
      if(!parent) {
        setEvents(rows);
      } else if(events.length > 0) {
        setEvents(old => old.map(oldEvent => updatedEvent(rows, oldEvent, parent, level!)));
      }
    }
  };
};

export default useEvents;
