import './App.css';

import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import EventList from './event-list';
import Login from './login';
import PrivateRoute from './private-route';
import withNavigation from './with-navigation';

function App() {
  const Events = withNavigation(EventList);
  const LoginNav = withNavigation(Login);

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute
          path="/events"
          render={() => (
            <div className="App">
              <Events />
            </div>
          )}
          exact
        />
        <Route path="/login" render={() => <LoginNav />} />
        <Route path="/" exact={false} render={() => <Redirect to="/events"/> }/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
