import { useSetRecoilState } from 'recoil';

import { userPool } from './environment';
import {
  extractUserFrom,
  getRefreshedSession,
  getSession,
  userState,
} from './user';

const useApplySession = () => {
  const setUser = useSetRecoilState(userState);
  // const logout = useLogout();
  return async () => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        const session = await getSession(cognitoUser);
        if (session.isValid()) {
          setUser(extractUserFrom(session));
          return;
        }
        const refreshedSession = await getRefreshedSession(
          cognitoUser,
          session.getRefreshToken(),
        );
        setUser(extractUserFrom(refreshedSession));
        return;
      }
      setUser(undefined);
      
    } catch (error) {
      // logout();
    }
  };
};

export default useApplySession;
