import './login.css';

import React, { ReactElement, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import useApplySession from './use-apply-session';
import useAuthenticate from './use-authenticate';
import useQueryParameter from './use-query-parameter';
import { userState } from './user';

export default function Login(): ReactElement {
  const authenticate = useAuthenticate();
  const redirect = useQueryParameter('redirect')[0];
  const user = useRecoilValue(userState);
  const applySession = useApplySession();
  const [username, setUser] = useState('');
  const [pass, setPass] = useState('');

  useEffect(() => {
    applySession();
  }, []);

  if (user) {
    return <Redirect to={redirect || '/events'} />;
  }

  return (
    <div className="login-view">
      <div className="form-title">Login</div>
      <div className="form-section username-input">
        <div className="form-title">Username</div>
        <input
          className="form-input"
          value={username}
          onChange={(e) => setUser(e.currentTarget.value)}
        />
      </div>
      <div className="form-section password-input">
        <div className="form-title">Password</div>
        <input
          type="password"
          className="form-input"
          value={pass}
          onChange={(e) => setPass(e.currentTarget.value)}
        />
      </div>
      <button
        type="button"
        className="form-button"
        onClick={() => {
          authenticate(username, pass);
        }}
      >
        Login
      </button>
    </div>
  );
}
